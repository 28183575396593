import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "navigation-flyout-teaser" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "navigation-flyout-teaser__box" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_element = _resolveComponent("image-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "navigation-flyout-teaser__link",
      href: _ctx.linkUrl
    }, [
      (_ctx.imageSrc)
        ? (_openBlock(), _createBlock(_component_image_element, {
            key: 0,
            src: [_ctx.imageSrc],
            class: "navigation-flyout-teaser__img",
            alt: _ctx.headline || _ctx.subline || _ctx.buttonText
          }, null, 8 /* PROPS */, ["src", "alt"]))
        : _createCommentVNode("v-if", true),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.headline)
          ? (_openBlock(), _createElementBlock("header", {
              key: 0,
              class: "navigation-flyout-teaser__header",
              innerHTML: _ctx.headline
            }, null, 8 /* PROPS */, _hoisted_4))
          : _createCommentVNode("v-if", true),
        _cache[0] || (_cache[0] = _createTextVNode()),
        (_ctx.subline)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "navigation-flyout-teaser__subline",
              innerHTML: _ctx.subline
            }, null, 8 /* PROPS */, _hoisted_5))
          : _createCommentVNode("v-if", true),
        _cache[1] || (_cache[1] = _createTextVNode()),
        (_ctx.buttonText)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              class: "navigation-flyout-teaser__button | button button--text button--icon-arrow-right",
              innerHTML: _ctx.buttonText
            }, null, 8 /* PROPS */, _hoisted_6))
          : _createCommentVNode("v-if", true)
      ])
    ], 8 /* PROPS */, _hoisted_2)
  ]))
}