import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "info-popup--root info-popup__wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "info-popup__label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$data.hideWrapper)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.toggleInfo && $options.toggleInfo(...args))),
          onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.mouseoverInfo && $options.mouseoverInfo(...args))),
          onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.mouseoutInfo && $options.mouseoutInfo(...args))),
          class: "info-popup__info-container"
        }, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "info-popup__info" }, null, -1 /* HOISTED */)),
          _cache[6] || (_cache[6] = _createTextVNode()),
          ($props.label || _ctx.$slots['label'])
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createTextVNode(_toDisplayString($props.label) + " ", 1 /* TEXT */),
                _renderSlot(_ctx.$slots, "label")
              ]))
            : _createCommentVNode("v-if", true)
        ], 32 /* NEED_HYDRATION */),
        _cache[8] || (_cache[8] = _createTextVNode()),
        ($data.visible || $data.hover)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "info-popup__wrapper-background",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.closePopup && $options.closePopup(...args)))
            }))
          : _createCommentVNode("v-if", true),
        _cache[9] || (_cache[9] = _createTextVNode()),
        ($data.visible || $data.hover)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["info-popup", `info-popup--${$data.popupPosition}`]),
              style: _normalizeStyle({
        width: $props.width
      })
            }, [
              ($props.showCloseIcon)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "info-popup__close",
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.closePopup && $options.closePopup(...args)))
                  }))
                : _createCommentVNode("v-if", true),
              _cache[7] || (_cache[7] = _createTextVNode()),
              _renderSlot(_ctx.$slots, "default")
            ], 6 /* CLASS, STYLE */))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}