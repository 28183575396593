import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 1,
  class: "flag__boxSetLayer"
}
const _hoisted_3 = { class: "flag__boxSetLayer-content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 3 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["href", "innerHTML", "target"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]

import {computed} from 'vue';
  import type Flags from '../../../bps/types/Flags';
  import type FlagList from '../../../bps/types/FlagList';
  import {t} from '@/bps/utilities/js/translations/translations';
  import LazyLoad from '../../../bps/components/lazyLoad/lazyLoad.vue';
  import InfoPopup from '../../../bps/components/infoPopup/infoPopup.vue';
  import ImageElement from '../../../bps/components/imageElement/imageElement.vue';
  import diContainer from '../../../bps/utilities/js/diContainer/diContainer';
  import {ProductDetailsSettings} from '../productDetails/productDetailsSettings';

  type FlagData = {
    type: keyof Flags,
    data: string | [string, string] | number
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'flags',
  props: {
    flags: {},
    lazyLoad: { type: Boolean, default: true },
    limit: { default: 3 },
    awardLimit: { default: 3 },
    whiteList: { default: () => [] },
    limitIgnoringFlags: { default: () => [] },
    enableLinks: { type: Boolean, default: false },
    targetBlankFlags: { default: () => [] },
    withSavingsMax: { type: Boolean, default: false }
  },
  setup(__props: any) {

  LazyLoad.name // LazyLoad is in use through component, but IDE doesn't recognize it

  const props = __props;

  const settings: ProductDetailsSettings = diContainer.get('productDetailsSettings');
  const defaultFlagOrder: FlagList = ['takeAndPayBenefitText', 'takeAndPayBenefitAppliedItemsText', 'free', 'new', 'newVintage', 'savings', 'savingsMax', 'awardInSet', 'award', 'stoerer', 'nachhaltigkeit', 'bio', 'vegan', 'quote', 'boxSetUrl'];

  const flagData = computed(() => {
    const flagList: FlagData[] = [];
    const flagOrder = props.whiteList || defaultFlagOrder;
    let flagLimit = props.limit;

    flagOrder.forEach((flagType) => {
      if (props.flags.subscription && flagType === 'subscription') {
        flagList.push({
          type: 'subscription', data: t('flags.subscription')
        });
      } else if (props.flags.new && flagType === 'new') {
        flagList.push({
          type: 'new', data: t('flags.new')
        });
      } else if (props.flags.newVintage && flagType === 'newVintage') {
        flagList.push({
          type: 'newVintage', data: t('flags.newVintage')
        });
      } else if (props.flags.savings && flagType === 'savings') {
        if (props.withSavingsMax && parseInt(props.flags.savings) > 50) {
          flagList.push({
            type: 'savingsMax', data: t('flags.savingsMax')
          })
        } else if (props.flags.savings !== '100') {
          flagList.push({
            type: 'savings', data: `${settings.flags.minusText}${props.flags.savings}${settings.flags.percentText}`
          })
        }
      } else if (props.flags.awardInSet && flagType === 'awardInSet') {
        flagList.push({
          type: 'awardInSet', data: settings.flags.src.awardInSetImage
        })
      } else if (props.flags.awardImageUrls && props.flags.awardImageUrls.length && flagType === 'award') {

        const awardLimit = Math.min(props.awardLimit, props.flags.awardImageUrls.length);

        for (let ndx = 0; ndx < awardLimit; ndx++) {
          flagList.push({
            type: 'award', data: props.flags.awardImageUrls[ndx]
          });
        }
      } else if (props.flags.bottleSize && flagType === 'bottleSize') {
        flagList.push({
          type: 'bottleSize', data: props.flags.bottleSize
        });
      } else if (props.flags.nonAlcoholic && flagType === 'nonAlcoholic') {
        flagList.push({
          type: 'nonAlcoholic', data: t('flags.nonAlcoholic')
        });
      } else if (props.flags.nachhaltigkeit && flagType === 'nachhaltigkeit') {
        flagList.push({
          type: 'nachhaltigkeit', data: t('flags.nachhaltigkeit')
        });
      } else if (props.flags.bio && flagType === 'bio') {
        flagList.push({
          type: 'bio', data: t('flags.bio')
        });
      } else if (props.flags.vegan && flagType === 'vegan') {
        flagList.push({
          type: 'vegan', data: t('flags.vegan')
        });
      } else if (props.flags.free && flagType === 'free') {
        flagList.push({
          type: 'free', data: t('flags.free')
        });
      } else if (props.flags.quote && flagType === 'quote') {
        flagList.push({
          type: 'quote', data: [props.flags.quote.text, props.flags.quote.author]
        });
      } else if (props.flags.takeAndPayBenefitText && flagType === 'takeAndPayBenefitText') {
        flagList.push({
          type: 'takeAndPayBenefitText', data: props.flags.takeAndPayBenefitText
        });
      } else if (props.flags.takeAndPayBenefitAppliedItemsText && flagType === 'takeAndPayBenefitAppliedItemsText') {
        flagList.push({
          type: 'takeAndPayBenefitAppliedItemsText', data: props.flags.takeAndPayBenefitAppliedItemsText
        });
      } else if (props.flags.boxSetUrl && flagType === 'boxSetUrl') {
        flagList.push({
          type: 'boxSetUrl', data: props.flags.boxSetUrl
        });
      } else if (props.flags.boxSetLayer?.noOfBottlesForBox && flagType === 'boxSetLayer') {
        flagList.push({
          type: 'boxSetLayer', data: null
        });
      } else if (props.flags.stoerer && props.flags.stoerer.length && flagType === 'stoerer') {
        for (let ndx = 0; ndx < props.flags.stoerer.length; ndx++) {
          flagList.push({
            type: 'stoerer', data: props.flags.stoerer[ndx]
          });
        }
      } else if (props.flags.personalized && flagType === 'personalized') {
        flagList.push({
          type: 'personalized', data: t('flags.personalized')
        });
      }
    });

    if (props.limitIgnoringFlags) {
      flagList.forEach((flag) => {
        if (props.limitIgnoringFlags.includes(flag.type)) {
          flagLimit++;
        }
      });
    }

    return flagList.slice(0, flagLimit);
  });


  function getTarget(flagType: string) {
    if (props.targetBlankFlags.indexOf(flagType) >= 0) {
      return '_blank';
    }
    return '_self';
  }

  function clickHandler(flag: FlagData, event: Event) {
    if (flag.type === 'award') {
      location.hash = '#awards';
    }

    if (flag.type === 'awardInSet') {
      location.hash = '#setContains';
    }

    if (flag.type === 'boxSetUrl') {
      event.preventDefault();
      window.location.href = flag.data as string;
    }
  }

  const flagType = (flag: FlagData) => {
    if (flag.type === 'stoerer' && flag.data.toString().toLowerCase() === 'exklusiv bei enzo') {
      return flag.type + '-exklusiv';
    }

    return flag.type;
  }

return (_ctx: any,_cache: any) => {
  return (flagData.value && flagData.value.length > 0)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.lazyLoad ? 'lazy-load' : 'ul'), {
        key: 0,
        tag: _ctx.lazyLoad ? 'ul' : null,
        class: "flags"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(flagData.value, (flag, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: _normalizeClass(["flag", [`flag--${flagType(flag)}`]]),
              onClick: ($event: any) => (clickHandler(flag, $event))
            }, [
              (flag.type === 'award' || flag.type === 'awardInSet')
                ? (_openBlock(), _createBlock(ImageElement, {
                    key: 0,
                    src: [flag.data],
                    alt: ""
                  }, null, 8 /* PROPS */, ["src"]))
                : (flag.type === 'boxSetLayer')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_ctx.flags.boxSetLayer)
                        ? (_openBlock(), _createBlock(InfoPopup, {
                            key: 0,
                            "show-close-icon": true,
                            position: "bottom-left"
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("span", {
                                  class: "flag__boxSetLayer-text",
                                  innerHTML: _ctx.$t('flags.boxSetLayer.text')
                                }, null, 8 /* PROPS */, _hoisted_4),
                                _cache[0] || (_cache[0] = _createTextVNode()),
                                _createVNode(ImageElement, {
                                  src: [_unref(settings).flags.src.boxSetImage],
                                  alt: ""
                                }, null, 8 /* PROPS */, ["src"])
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('flags.boxSetLayer.info', [_ctx.flags.boxSetLayer.noOfBottlesForBox])), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : (flag.type === 'boxSetUrl')
                    ? (_openBlock(), _createBlock(ImageElement, {
                        key: 2,
                        src: [_unref(settings).flags.src.boxSetImage],
                        alt: ""
                      }, null, 8 /* PROPS */, ["src"]))
                    : (flag.type === 'quote')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          _createElementVNode("span", {
                            class: "flag__quote",
                            innerHTML: flag.data[0]
                          }, null, 8 /* PROPS */, _hoisted_6),
                          _cache[1] || (_cache[1] = _createTextVNode()),
                          _createElementVNode("span", {
                            class: "flag__author",
                            innerHTML: flag.data[1]
                          }, null, 8 /* PROPS */, _hoisted_7)
                        ]))
                      : (flag.type === 'takeAndPayBenefitText' || flag.type === 'takeAndPayBenefitAppliedItemsText')
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 4,
                            class: "flag__takeAndBenefit",
                            innerHTML: flag.data
                          }, null, 8 /* PROPS */, _hoisted_8))
                        : (flag.type === 'nachhaltigkeit')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                              (_ctx.enableLinks)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    href: _ctx.$t('flags.nachhaltigkeit.link'),
                                    class: "flag--link",
                                    innerHTML: flag.data,
                                    target: getTarget(flag.type)
                                  }, null, 8 /* PROPS */, _hoisted_9))
                                : (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    innerHTML: flag.data
                                  }, null, 8 /* PROPS */, _hoisted_10))
                            ], 64 /* STABLE_FRAGMENT */))
                          : (_openBlock(), _createElementBlock("span", {
                              key: 6,
                              innerHTML: flag.data
                            }, null, 8 /* PROPS */, _hoisted_11))
            ], 10 /* CLASS, PROPS */, _hoisted_1))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["tag"]))
    : _createCommentVNode("v-if", true)
}
}

})