import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "lightbox__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dynamic_template = _resolveComponent("dynamic-template")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return (_ctx.isOpen)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["lightbox", _ctx.additionalClasses])
      }, [
        (_ctx.showCloseButton)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "lightbox__close-btn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close('closeButton')))
            }))
          : _createCommentVNode("v-if", true),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.inlineContent)
            ? (_openBlock(), _createBlock(_component_dynamic_template, {
                key: 0,
                html: _ctx.inlineContent
              }, null, 8 /* PROPS */, ["html"]))
            : _createCommentVNode("v-if", true),
          _cache[1] || (_cache[1] = _createTextVNode()),
          _renderSlot(_ctx.$slots, "default")
        ])
      ], 2 /* CLASS */)), [
        [_directive_outside_click, _ctx.closeOutside, true]
      ])
    : _createCommentVNode("v-if", true)
}