import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "read-more__slot",
  ref: "slotElement"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["read-more", {
      'read-more--open': _ctx.open,
      'read-more--closed': !_ctx.open,
      'read-more--button-is-visible': !_ctx.disabled
    }])
  }, [
    _createElementVNode("div", {
      class: "read-more__container",
      style: _normalizeStyle(_ctx.style)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ], 512 /* NEED_PATCH */)
    ], 4 /* STYLE */),
    _cache[1] || (_cache[1] = _createTextVNode()),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "read-more__btn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle())),
          ref: "lessButton"
        }, _toDisplayString(_ctx.open ? _ctx.$t(_ctx.textLess) : _ctx.$t(_ctx.textMore)), 513 /* TEXT, NEED_PATCH */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}