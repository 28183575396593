import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-id", "data-item-id", "data-artnr", "data-wban"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "product-box-wide__personalization-banner-wrapper"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 2,
  class: "product-box-wide__segment-conversion"
}
const _hoisted_6 = { class: "product-box-wide__price-wrapper product-box-wide__price-wrapper--no-price" }
const _hoisted_7 = { class: "product-box__buttons" }
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_box_wide_segment_image = _resolveComponent("product-box-wide-segment-image")!
  const _component_product_box_wide_segment_center = _resolveComponent("product-box-wide-segment-center")!
  const _component_product_box_wide_segment_conversion = _resolveComponent("product-box-wide-segment-conversion")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["product-box-wide", {
      'product-box--in-basket': _ctx.productBoxData.inBasket,
      'product-box--error': _ctx.state === 'error',
      'product-box-wide--personalization': _ctx.productBoxData.personalPreferenceMessageKey,
      [`product-box--type-${_ctx.productBoxData.productBoxType}`]: true,
      'product-box--highlight': _ctx.productBoxData.highlightArticle,
    }]),
    "data-id": _ctx.productBoxData.id,
    "data-item-id": _ctx.productBoxData.itemId,
    "data-artnr": _ctx.productBoxData.trackingData.artNr,
    "data-wban": _ctx.productBoxData.trackingData.wban
  }, [
    (_ctx.productBoxData.highlightArticle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "product-box-wide__highlight-text",
          innerHTML: _ctx.$t(`highlightArticle.title.${_ctx.productBoxData.highlightArticle}`)
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _cache[0] || (_cache[0] = _createTextVNode()),
    ($setup.settingsWide.personalisationTop && _ctx.productBoxData.personalPreferenceMessageKey)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "product-box-wide__personalization-banner personalization-banner",
            innerHTML: _ctx.$t(_ctx.productBoxData.personalPreferenceMessageKey)
          }, null, 8 /* PROPS */, _hoisted_4)
        ]))
      : _createCommentVNode("v-if", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createVNode(_component_product_box_wide_segment_image, {
      "product-box-data": _ctx.productBoxData,
      settings: $setup.settingsWide,
      "img-src": $setup.imgSrc,
      "is-box-type-vertical": _ctx.isBoxTypeVertical,
      "is-bottle": _ctx.isBottle
    }, null, 8 /* PROPS */, ["product-box-data", "settings", "img-src", "is-box-type-vertical", "is-bottle"]),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createVNode(_component_product_box_wide_segment_center, {
      "description-excerpt": $options.descriptionExcerpt,
      "product-box-data": _ctx.productBoxData,
      "show-availability": _ctx.showAvailability,
      "minimum-rating": _ctx.minimumRating,
      "basket-url": _ctx.basketUrl,
      state: _ctx.state,
      settings: $setup.settingsWide
    }, null, 8 /* PROPS */, ["description-excerpt", "product-box-data", "show-availability", "minimum-rating", "basket-url", "state", "settings"]),
    _cache[3] || (_cache[3] = _createTextVNode()),
    (_ctx.productBoxData.zeroPriceItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("a", {
                class: "product-box__status product-box__status--unavailable",
                href: _ctx.productBoxData.url
              }, _toDisplayString(_ctx.$t('availability.no-price')), 9 /* TEXT, PROPS */, _hoisted_8)
            ])
          ])
        ]))
      : (_openBlock(), _createBlock(_component_product_box_wide_segment_conversion, {
          key: 3,
          "product-box-data": _ctx.productBoxData,
          settings: $setup.settingsWide,
          state: _ctx.state,
          "hide-counter-button": _ctx.hideCounterButton,
          "food-specification-link": _ctx.foodSpecificationLink,
          "hide-price": _ctx.isPriceHidden,
          "show-availability": _ctx.showAvailability,
          "minimum-rating": _ctx.minimumRating,
          "add-successor-active": _ctx.addSuccessorActive,
          "basket-url": _ctx.basketUrl,
          "product-link": _ctx.productLink,
          onWishlistClicked: _ctx.toggleWishlist,
          onAddToBasket: _ctx.addToBasket,
          onQuantityChange: _ctx.changeQuantity,
          onOnRequestClicked: _ctx.openOnRequestLightBox
        }, null, 8 /* PROPS */, ["product-box-data", "settings", "state", "hide-counter-button", "food-specification-link", "hide-price", "show-availability", "minimum-rating", "add-successor-active", "basket-url", "product-link", "onWishlistClicked", "onAddToBasket", "onQuantityChange", "onOnRequestClicked"]))
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}