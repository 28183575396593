import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  key: 0,
  class: "search-autosuggest__suggestions-name"
}
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["search-autosuggest__suggestions-list", {
      'search-autosuggest__suggestions-list--category': _ctx.suggestionItem.type === 'categorySuggestions',
      'search-autosuggest__suggestions-list--top-search': _ctx.suggestionItem.type === 'topSearchSuggestions',
      'search-autosuggest__suggestions-list--product': _ctx.suggestionItem.type === 'productSuggestions'
    }]),
    role: "listbox",
    "aria-label": _ctx.suggestionItem.suggestions[0].title
  }, [
    (_ctx.suggestionItem.suggestions[0].title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.suggestionItem.suggestions[0].title), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestionItem.suggestions, (suggestion, suggestionIndex) => {
      return (_openBlock(), _createElementBlock("a", {
        class: _normalizeClass(["search-autosuggest__suggestion", {
        'search-autosuggest__suggestion--active': _ctx.activeListIndex === _ctx.listIndex && _ctx.activeSuggestionIndex === suggestionIndex
      }]),
        href: suggestion.link ? suggestion.link : '#',
        onClick: _withModifiers(($event: any) => (_ctx.chooseSuggestion(_ctx.listIndex, suggestionIndex)), ["prevent"]),
        key: suggestionIndex
      }, [
        (suggestion.imageVersions)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: suggestion.imageVersions.thumbnail,
              alt: suggestion.value
            }, null, 8 /* PROPS */, _hoisted_4))
          : _createCommentVNode("v-if", true),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("span", null, _toDisplayString(suggestion.value), 1 /* TEXT */)
      ], 10 /* CLASS, PROPS */, _hoisted_3))
    }), 128 /* KEYED_FRAGMENT */))
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}