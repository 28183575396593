import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "navigation__link-name"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["aria-expanded"]
const _hoisted_5 = { class: "visually-hidden" }

import {ref, onMounted} from 'vue';
  import pigeon from '../../../bps/utilities/js/pigeon/pigeon';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'navigationElement',
  props: {
    id: { default: null },
    navName: { default: '' },
    navLink: { default: '' },
    hasSubNavigation: { type: Boolean, default: false },
    shouldRemoveMenuLink: { type: Boolean, default: false }
  },
  setup(__props: any) {

  const props = __props;

  const visible = ref(false);
  const hover = ref(false);
  const mouseout = ref(false);
  const isTouch = ref(false);
  const ariaExpanded = ref(false);
  const subVisible = ref(false);

  const clickNaviElement = (event: Event) => {
    const clickElement = (event.target as HTMLElement).closest('.navigation__element');
    if (isTouch.value && !visible.value && clickElement.id === (event.currentTarget as HTMLElement).id) {
      event.preventDefault();
      visible.value = true;
    } else {
      visible.value = false;
    }
  };

  const close = (event: Event) => {
    if (isTouch.value && visible.value) {
      hover.value = false;
      visible.value = false;
      hideSubmenu();
    }
  };

  const mouseoverElement = () => {
    if (!isTouch.value) {
      hover.value = true;
      mouseout.value = false;
    }
  };

  const mouseoutElement = () => {
    if (!isTouch.value) {
      hover.value = false;
      visible.value = false;
      mouseout.value = true;
    }
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      if (!ariaExpanded.value) {
        // close all other submenus
        pigeon.publish('navigation:hideSubmenu');
        setTimeout(() => {
          showSubmenu();
        }, 10);
      } else {
        hideSubmenu();
      }
    }

    if (event.code === 'Escape')  {
      hideSubmenu();
    }
  };

  const hideSubmenu = () => {
    ariaExpanded.value = false;
    subVisible.value = false;
  };

  const showSubmenu = () => {
    ariaExpanded.value = true;
    subVisible.value = true;
  };

  onMounted(() => {
    isTouch.value = 'ontouchstart' in window;
    pigeon.subscribe('navigation:hideSubmenu', hideSubmenu);
  });

return (_ctx: any,_cache: any) => {
  const _directive_outside_click = _resolveDirective("outside-click")!

  return _withDirectives((_openBlock(), _createElementBlock("li", {
    id: props.id,
    class: _normalizeClass(["navigation__element", {
      'navigation__element--is-touch': isTouch.value,
      'navigation__element--hover': hover.value,
      'navigation__element--visible': visible.value,
      'navigation__element--mouseout': mouseout.value,
      'navigation__element--sub-visible': subVisible.value
    }]),
    onKeydown: handleKeydown,
    onClick: clickNaviElement,
    onMouseover: mouseoverElement,
    onMouseleave: mouseoutElement
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["navigation__link", {
        'navigation__link--sub': props.hasSubNavigation
      }])
    }, [
      (props.shouldRemoveMenuLink)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(props.navName), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "navigation__link-name",
            href: props.navLink
          }, [
            _createElementVNode("span", null, _toDisplayString(props.navName), 1 /* TEXT */)
          ], 8 /* PROPS */, _hoisted_3)),
      _cache[0] || (_cache[0] = _createTextVNode()),
      (props.hasSubNavigation)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: _normalizeClass(["navigation-flyout__sub-button", { 'navigation-flyout__sub-button--expanded': ariaExpanded.value }]),
            "aria-expanded": ariaExpanded.value
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('navigation.submenu.button.description', [props.navName])), 1 /* TEXT */)
          ], 10 /* CLASS, PROPS */, _hoisted_4))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "flyout")
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1)), [
    [_directive_outside_click, close, true]
  ])
}
}

})