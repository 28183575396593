<template>
  <li
    :id="props.id"
    class="navigation__element"
    :class="{
      'navigation__element--is-touch': isTouch,
      'navigation__element--hover': hover,
      'navigation__element--visible': visible,
      'navigation__element--mouseout': mouseout,
      'navigation__element--sub-visible': subVisible
    }"
    @keydown="handleKeydown"
    @click="clickNaviElement"
    @mouseover="mouseoverElement"
    @mouseleave="mouseoutElement"
    v-outside-click:[true]="close"
  >
    <span
      class="navigation__link"
      :class="{
        'navigation__link--sub': props.hasSubNavigation
      }"
    >
      <span
        v-if="props.shouldRemoveMenuLink"
        class="navigation__link-name"
      >
        {{ props.navName }}
      </span>
      <a
        v-else
        class="navigation__link-name"
        :href="props.navLink"
      >
        <span>
          {{ props.navName }}
        </span>
      </a>
      <button
        class="navigation-flyout__sub-button"
        :class="{ 'navigation-flyout__sub-button--expanded': ariaExpanded }"
        :aria-expanded="ariaExpanded"
        v-if="props.hasSubNavigation"
      >
        <span class="visually-hidden">{{ $t('navigation.submenu.button.description', [props.navName]) }}</span>
      </button>
    </span>
    <slot name="flyout" />
  </li>
</template>

<script setup lang="ts">
  import {ref, onMounted} from 'vue';
  import pigeon from '../../../bps/utilities/js/pigeon/pigeon';

  const props = withDefaults(defineProps<{
    id?: string;
    navName?: string;
    navLink?: string;
    hasSubNavigation?: boolean;
    shouldRemoveMenuLink?: boolean;
  }>(), {
    id: null,
    navName: '',
    navLink: '',
    hasSubNavigation: false,
    shouldRemoveMenuLink: false,
  });

  const visible = ref(false);
  const hover = ref(false);
  const mouseout = ref(false);
  const isTouch = ref(false);
  const ariaExpanded = ref(false);
  const subVisible = ref(false);

  const clickNaviElement = (event: Event) => {
    const clickElement = (event.target as HTMLElement).closest('.navigation__element');
    if (isTouch.value && !visible.value && clickElement.id === (event.currentTarget as HTMLElement).id) {
      event.preventDefault();
      visible.value = true;
    } else {
      visible.value = false;
    }
  };

  const close = (event: Event) => {
    if (isTouch.value && visible.value) {
      hover.value = false;
      visible.value = false;
      hideSubmenu();
    }
  };

  const mouseoverElement = () => {
    if (!isTouch.value) {
      hover.value = true;
      mouseout.value = false;
    }
  };

  const mouseoutElement = () => {
    if (!isTouch.value) {
      hover.value = false;
      visible.value = false;
      mouseout.value = true;
    }
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.code === 'Space') {
      if (!ariaExpanded.value) {
        // close all other submenus
        pigeon.publish('navigation:hideSubmenu');
        setTimeout(() => {
          showSubmenu();
        }, 10);
      } else {
        hideSubmenu();
      }
    }

    if (event.code === 'Escape')  {
      hideSubmenu();
    }
  };

  const hideSubmenu = () => {
    ariaExpanded.value = false;
    subVisible.value = false;
  };

  const showSubmenu = () => {
    ariaExpanded.value = true;
    subVisible.value = true;
  };

  onMounted(() => {
    isTouch.value = 'ontouchstart' in window;
    pigeon.subscribe('navigation:hideSubmenu', hideSubmenu);
  });
</script>
