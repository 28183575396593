import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "burger-menu__wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "burger-menu__loader"
}
const _hoisted_3 = { class: "burger-menu__nav-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_burger_nav_tree = _resolveComponent("burger-nav-tree")!

  return (_ctx.showMenu)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "burger-menu__wrapper-background",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
        }),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _createElementVNode("nav", {
          class: _normalizeClass(["burger-menu", {
        'burger-menu--open': _ctx.isOpen
      }]),
          ref: "burgerMenu",
          style: _normalizeStyle(_ctx.menuStyle)
        }, [
          _createElementVNode("span", {
            class: "burger-menu__close-button",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"])),
            role: "button"
          }),
          _cache[3] || (_cache[3] = _createTextVNode()),
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.localisationClick()))
          }, [
            _renderSlot(_ctx.$slots, "i18n")
          ]),
          _cache[4] || (_cache[4] = _createTextVNode()),
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2))
            : _createCommentVNode("v-if", true),
          _cache[5] || (_cache[5] = _createTextVNode()),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.showMenu)
              ? (_openBlock(), _createBlock(_component_burger_nav_tree, {
                  key: 0,
                  class: "burger-menu__navtree",
                  categories: _ctx.navigationTree,
                  headline: _ctx.headline || _ctx.$t('navigation.burger.menu'),
                  "breadcrumb-tree": _ctx.breadcrumbTree,
                  "is-loading": _ctx.isLoading,
                  onLevelChange: _ctx.navTreeLevelChangeHandler
                }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                  _renderList(_ctx.$slots, (slot, key) => {
                    return {
                      name: key,
                      fn: _withCtx(() => [
                        _renderSlot(_ctx.$slots, key)
                      ])
                    }
                  })
                ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["categories", "headline", "breadcrumb-tree", "is-loading", "onLevelChange"]))
              : _createCommentVNode("v-if", true)
          ])
        ], 6 /* CLASS, STYLE */)
      ]))
    : _createCommentVNode("v-if", true)
}