import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["srcset", "media"]
const _hoisted_2 = ["srcset", "media"]
const _hoisted_3 = ["srcset", "media"]
const _hoisted_4 = ["srcset", "media"]
const _hoisted_5 = ["src", "alt", "width", "height", "loading"]
const _hoisted_6 = ["srcset"]
const _hoisted_7 = ["srcset"]
const _hoisted_8 = ["src", "alt", "width", "height", "loading"]
const _hoisted_9 = { key: 2 }
const _hoisted_10 = ["srcset"]
const _hoisted_11 = ["srcset"]
const _hoisted_12 = ["src", "alt", "width", "height", "loading"]
const _hoisted_13 = ["src", "alt", "width", "height", "loading"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["image-element", {
      'image-element--load-error': _ctx.isError
    }])
  }, [
    (_ctx.imgSrcMobile && _ctx.imgSrcDesktop && _ctx.imgSrc)
      ? (_openBlock(), _createElementBlock("picture", {
          key: 0,
          class: _normalizeClass(["image-element__pic", _ctx.pictureClass])
        }, [
          (_ctx.loadWebp)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("source", {
                  srcset: _ctx.getWebpSrc(_ctx.imgSrcMobile),
                  media: `(max-width: ${_ctx.breakpoint}px)`,
                  type: "image/webp"
                }, null, 8 /* PROPS */, _hoisted_1),
                _cache[4] || (_cache[4] = _createTextVNode()),
                _createElementVNode("source", {
                  srcset: _ctx.getWebpSrc(_ctx.imgSrcDesktop),
                  media: `(min-width: ${_ctx.breakpoint}px)`,
                  type: "image/webp"
                }, null, 8 /* PROPS */, _hoisted_2)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _cache[5] || (_cache[5] = _createTextVNode()),
          _createElementVNode("source", {
            srcset: _ctx.getWebpFallbackSrc(_ctx.imgSrcMobile),
            media: `(max-width: ${_ctx.breakpoint}px)`
          }, null, 8 /* PROPS */, _hoisted_3),
          _cache[6] || (_cache[6] = _createTextVNode()),
          _createElementVNode("source", {
            srcset: _ctx.getWebpFallbackSrc(_ctx.imgSrcDesktop),
            media: `(min-width: ${_ctx.breakpoint}px)`
          }, null, 8 /* PROPS */, _hoisted_4),
          _cache[7] || (_cache[7] = _createTextVNode()),
          _createElementVNode("img", {
            class: _normalizeClass(["image-element__img", _ctx.imgClass]),
            src: _ctx.imgSrc,
            alt: _ctx.alt,
            width: _ctx.width,
            height: _ctx.height,
            loading: _ctx.loading,
            onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.errorHandler && _ctx.errorHandler(...args)))
          }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_5)
        ], 2 /* CLASS */))
      : (!_ctx.imgSrcMobile && _ctx.imgSrcDesktop && _ctx.imgSrc)
        ? (_openBlock(), _createElementBlock("picture", {
            key: 1,
            class: _normalizeClass(["image-element__pic", _ctx.pictureClass])
          }, [
            (_ctx.loadWebp)
              ? (_openBlock(), _createElementBlock("source", {
                  key: 0,
                  srcset: _ctx.getWebpSrc(_ctx.imgSrcDesktop),
                  type: "image/webp"
                }, null, 8 /* PROPS */, _hoisted_6))
              : _createCommentVNode("v-if", true),
            _cache[8] || (_cache[8] = _createTextVNode()),
            _createElementVNode("source", {
              srcset: _ctx.getWebpFallbackSrc(_ctx.imgSrcDesktop)
            }, null, 8 /* PROPS */, _hoisted_7),
            _cache[9] || (_cache[9] = _createTextVNode()),
            _createElementVNode("img", {
              class: _normalizeClass(["image-element__img", _ctx.imgClass]),
              src: _ctx.imgSrc,
              alt: _ctx.alt,
              width: _ctx.width,
              height: _ctx.height,
              loading: _ctx.loading,
              onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.errorHandler && _ctx.errorHandler(...args)))
            }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_8)
          ], 2 /* CLASS */))
        : (_ctx.loadWebp)
          ? (_openBlock(), _createElementBlock("picture", _hoisted_9, [
              _createElementVNode("source", {
                srcset: _ctx.getWebpSrc(_ctx.imgSrc),
                type: "image/webp"
              }, null, 8 /* PROPS */, _hoisted_10),
              _cache[10] || (_cache[10] = _createTextVNode()),
              _createElementVNode("source", {
                srcset: _ctx.getWebpFallbackSrc(_ctx.imgSrc),
                media: "(min-width: 1px)"
              }, null, 8 /* PROPS */, _hoisted_11),
              _cache[11] || (_cache[11] = _createTextVNode()),
              _createElementVNode("img", {
                class: _normalizeClass(["image-element__img", _ctx.imgClass]),
                src: _ctx.imgSrc,
                alt: _ctx.alt,
                width: _ctx.width,
                height: _ctx.height,
                loading: _ctx.loading,
                onError: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.errorHandler && _ctx.errorHandler(...args)))
              }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_12)
            ]))
          : (_openBlock(), _createElementBlock("img", {
              key: 3,
              class: _normalizeClass(["image-element__img", _ctx.imgClass]),
              src: _ctx.imgSrc,
              alt: _ctx.alt,
              width: _ctx.width,
              height: _ctx.height,
              loading: _ctx.loading,
              onError: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.errorHandler && _ctx.errorHandler(...args)))
            }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_13))
  ], 2 /* CLASS */))
}