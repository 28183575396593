import JsComponent from '../jsComponent/jsComponent.js';
import _debounce from 'lodash/debounce';
import scrollTo from 'scroll-to';

const defaultOptions = {
  topOffset: 1000
};

class GoTop extends JsComponent {
  constructor(mainElement, name = 'go-top', options) {
    super(mainElement, name);
    this.mainElement = mainElement;
    this.createClassNames(
      '--sticky'
    );

    this.isSticky = false;
    this.options = Object.assign({}, defaultOptions, options);
    this.bindEvents();
  }

  bindEvents() {
    const scrollListener = _debounce(this.handleScroll.bind(this));
    window.addEventListener('scroll', scrollListener);

    this.mainElement.addEventListener('click', (evt) => {
      evt.preventDefault();
      scrollTo(0, 0, {duration: 500});
    });
  }

  handleScroll() {
    this.toggleSticky();
  }

  toggleSticky() {
    if (window.pageYOffset > this.options.topOffset) {
      this.mainElement.classList.add(this.classNames.sticky);
    } else {
      this.mainElement.classList.remove(this.classNames.sticky);
    }
  }
}

export default GoTop;
