import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "product-box-wide__segment-conversion" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 4,
  class: "product-box-wide__product-flags product-box-wide__product-flags--conversion"
}
const _hoisted_5 = { class: "product-box-wide__price-wrapper" }
const _hoisted_6 = ["href", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ratings = _resolveComponent("ratings")!
  const _component_flags = _resolveComponent("flags")!
  const _component_availability_info = _resolveComponent("availability-info")!
  const _component_price_info = _resolveComponent("price-info")!
  const _component_product_qty_and_cart_btn = _resolveComponent("product-qty-and-cart-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.productBoxData.recurringOrderFollowUpPacket)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["product-box-wide__button-wishlist product-box__button-wishlist", {
        'product-box__button-wishlist--filled': _ctx.isWishlistEnabled && _ctx.productBoxData.inWishlist,
        'product-box__button-wishlist--remove': !_ctx.isWishlistEnabled,
        'product-box__button-wishlist--loading': _ctx.state === 'loadingWishlist',
      }]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('wishlistClicked')), ["prevent"]))
        }, null, 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _cache[6] || (_cache[6] = _createTextVNode()),
    (_ctx.productBoxData.ratings > _ctx.minimumRating)
      ? (_openBlock(), _createBlock(_component_ratings, {
          key: 1,
          class: "product-box__ratings",
          rating: _ctx.productBoxData.ratings,
          "ratings-counter": _ctx.productBoxData.ratingsCounter,
          type: _ctx.settings.rating.type
        }, null, 8 /* PROPS */, ["rating", "ratings-counter", "type"]))
      : _createCommentVNode("v-if", true),
    _cache[7] || (_cache[7] = _createTextVNode()),
    (_ctx.productBoxData.inBasket)
      ? (_openBlock(), _createElementBlock("a", {
          key: 2,
          class: "product-box__status product-box__status--basket product-box-wide__status",
          href: _ctx.basketUrl
        }, _toDisplayString(_ctx.$t('ads.ads_top_info.article_in_basket')), 9 /* TEXT, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _cache[8] || (_cache[8] = _createTextVNode()),
    (_ctx.productBoxData.successorInBasket && !_ctx.productBoxData.inBasket && _ctx.addSuccessorActive)
      ? (_openBlock(), _createElementBlock("a", {
          key: 3,
          class: "product-box__status product-box__status--basket",
          href: _ctx.basketUrl
        }, _toDisplayString(_ctx.$t('ads.ads_top_info.successor_in_basket')), 9 /* TEXT, PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true),
    _cache[9] || (_cache[9] = _createTextVNode()),
    (_ctx.productBoxData.flags && !_ctx.productBoxData.inBasket)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_flags, {
            flags: _ctx.productBoxData.flags,
            limit: 1,
            "white-list": _ctx.settings.flags.whitelistConversion
          }, null, 8 /* PROPS */, ["flags", "white-list"])
        ]))
      : _createCommentVNode("v-if", true),
    _cache[10] || (_cache[10] = _createTextVNode()),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.showAvailability && !_ctx.productBoxData.recurringOrderFollowUpPacket)
        ? (_openBlock(), _createBlock(_component_availability_info, {
            key: 0,
            class: "product-box-wide__availability product-box__availability",
            availability: _ctx.productBoxData.availability
          }, null, 8 /* PROPS */, ["availability"]))
        : _createCommentVNode("v-if", true),
      _cache[4] || (_cache[4] = _createTextVNode()),
      (!_ctx.hidePrice && _ctx.productBoxData.price)
        ? (_openBlock(), _createBlock(_component_price_info, {
            key: 1,
            class: "product-box-wide__price product-box__price",
            value: _ctx.productBoxData.price,
            alcohol: _ctx.productBoxData.alcohol,
            "show-star": false,
            "show-tax": true
          }, null, 8 /* PROPS */, ["value", "alcohol"]))
        : _createCommentVNode("v-if", true),
      _cache[5] || (_cache[5] = _createTextVNode()),
      _createVNode(_component_product_qty_and_cart_btn, {
        class: "product-box-wide__buttons",
        "product-data": _ctx.productBoxData,
        "product-link": _ctx.productLink,
        state: _ctx.state,
        "add-successor-active": _ctx.addSuccessorActive,
        onQuantityChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('quantityChange', $event))),
        onAddToBasket: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('addToBasket', $event))),
        onOnRequestClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('onRequestClicked', $event)))
      }, null, 8 /* PROPS */, ["product-data", "product-link", "state", "add-successor-active"])
    ]),
    _cache[11] || (_cache[11] = _createTextVNode()),
    (_ctx.productBoxData.showFoodSpecificationLink && !_ctx.hideCounterButton)
      ? (_openBlock(), _createElementBlock("a", {
          key: 5,
          href: _ctx.foodSpecificationLink,
          class: "product-box__link",
          innerHTML: _ctx.$t('detail.product.characteristics')
        }, null, 8 /* PROPS */, _hoisted_6))
      : _createCommentVNode("v-if", true)
  ]))
}